import React from 'react'
import "core-js/modules/es6.promise";
import "core-js/modules/es6.array.iterator";
import assignIn from 'lodash/assignIn'
import get from 'lodash/get'
import { render } from 'react-dom'
import App from './App'
import getQueryVariable from './tools/getQueryVariable'
import popup from './views/Popup/popup'
import { refreshUser, fetchUserSubscription, onUser, logout, initOptionalCC } from './redux/user/helpers'
import { capitalize, cipher, getUnixTimestamp} from './tools/utils'
import './assets/css/fix.css'
import './assets/css/base.scss'
import pageStore from './redux/page/store'
import userStore from './redux/user/store'
import { fetchPage } from './redux/page/actions'
import { initCleverTap, addUserProfile } from './tools/analytics/cleverTap'
import { requestUserPermission, anonymousUserPushNotification } from './tools/webWorker'
import { pushToDatalayer } from "~/tools/analytics/gtmDatalayer";
import './adobePass/adobePrimeCallbacks.js'
import { getProp } from './tools/custom-lodash'
import Cookies from 'js-cookie'
import { initBounce } from'./verimatrix/apis'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import axios from 'axios'

localStorage.removeItem("vrtm")
var location = window.location
var route = location.pathname
var requestHost = location.hostname
var domainNames = window.settings.domainNames
if(!domainNames.includes(requestHost)) {
	var originPathElement = document.getElementById('origin-path');
	var originPath = null;
	if(originPathElement) {
		originPath = originPathElement.getAttribute('data-seo');
	}
	if(originPath && route !== originPath) {
		route = originPath
	}
}

if (route.length > 1) route = route.replace(/\/$/, '')
var page = pageStore.getState()

var setUserStatus = function(tokenExpired = false) {
	return new Promise((resolve, reject)=>{
		const sessionFlag = sessionStorage.getItem("sessionFlag") || false;
		if (!sessionFlag || (sessionFlag && sessionFlag === "false") || tokenExpired) {
			refreshUser().then(() => {
				var userInfo = userStore.getState().user || {}
				userInfo.userId && userInfo.isSubscribed && fetchUserSubscription();
				resolve()
			});
		}else{
			resolve()
		}
		sessionStorage.setItem('sessionFlag', true);
	})
}

async function fetchPageCall() {
	await setUserStatus()
	pageStore.dispatch(fetchPage(route))
}

 function fetchConfig(url) {
	return axios({
        method: 'GET',
        url: url,
      }).then( res => {
        return res.data
      }).catch(err => {
        return err
      })
 }

 function fetchSiteConfig () {
	let siteValue = localStorage.getItem("genericMessages")
	let url = window.settings.layoutUrlBase+window.settings.id+'/main.json'
	var headers = {
	  'If-Modified-Since': new Date().toString()
	 }
	fetch(url, {
	  method: "GET",
	  headers: headers
	  })
	  .then(response => { 
		if(response.status > 200 && response.status < 400) {
		  if(siteValue !== undefined && siteValue !== "undefined" && siteValue !== "null" && siteValue !== null) {
			window.settings["genericMessages"] = JSON.parse(siteValue)
		  } else {
			fetchConfig(url)
			  .then(data => {
				localStorage.setItem("genericMessages",JSON.stringify(data.genericMessages))
				window.settings["genericMessages"] = data.genericMessages
			  })     
		  } 
		} else {
		  if(response && !response.genericMessages) {
			fetchConfig(url)
			.then((res) => {
				localStorage.setItem("genericMessages",JSON.stringify(res.genericMessages))	
				window.settings["genericMessages"] = res.genericMessages
			})
		  } else {
			localStorage.setItem("genericMessages",JSON.stringify(response.genericMessages))
			window.settings["genericMessages"] = response.genericMessages
		  }  
		}   
	  })
	  .catch(e => {
		console.log(e)
		console.log(`Error occurred ${new Date()}\n`)
	  })
  }
  function importScriptFunc() {
	if(aisresponse){
		import('./verimatrix/callbacks').then(initializeRawInflateFunc => {
			const {initializeRawInflate} = initializeRawInflateFunc
			initializeRawInflate()
		});
	}
	if(get(window.settings, 'serviceType') === 'TVE'){
		import('./adobePass/loadAccessEnabler').then(initializeAccessEnablerFunc => {
			const {initializeAccessEnabler} = initializeAccessEnablerFunc
			initializeAccessEnabler()
		});
	}
  }

  function fetchSiteConfigWeb () {
	let siteValue = localStorage.getItem("pages")
	let url = window.settings.Web
	var headers = {
	  'If-Modified-Since': new Date().toString()
	 }
	fetch(url, {
	  method: "GET",
	  headers: headers
	  })
	  .then(response => { 
		if(response.status > 200 && response.status < 400) {
			if(siteValue !== undefined && siteValue !== "undefined" && siteValue !== "null" && siteValue !== null) {
			window.settings["pages"] = JSON.parse(siteValue)
		  	} else {
			fetchConfig(url)
			  .then(data => {
				localStorage.setItem("pages",JSON.stringify(data.pages))
				window.settings["pages"] = data.pages
			  })     
		  } 
		} else {
			if(response && !response.pages) {
				fetchConfig(url)
				.then((res) => {
					localStorage.setItem("pages",JSON.stringify(res.pages))
					window.settings["pages"] = res.pages
				})
			  } else {
				localStorage.setItem("pages",JSON.stringify(response.pages))
				window.settings["pages"] = response.pages
			  }	  
		}   
	  })
	  .catch(e => {
		console.log(e)
		console.log(`Error occurred ${new Date()}\n`)
	  })
  }
 
  
if (!(page.data && page.data.route === route) && !page.fetchingPage)
	fetchPageCall()
fetchSiteConfig()
fetchSiteConfigWeb()

const oneTrustEnabled = (window.settings.privacy && window.settings.privacy.oneTrust && window.settings.privacy.oneTrust.enable) || null;
if(oneTrustEnabled){
	initCleverTap()
} else {
	initOptionalCC()
}

var userInfo = userStore.getState().user || {}
if(userInfo.userId && userInfo.istveLoggedIn && !Cookies.get('checkedTveSession')){
	(async function(){
		const stillTveLoggedIn = await initBounce()
		if(!stillTveLoggedIn){
			logout()
		}else{
			Cookies.set('checkedTveSession', true,{ expires: 1 })
		}
	})();	
}
var aisresponse = getQueryVariable('aisresponse')
importScriptFunc()

if (localStorage.getItem('user')) {
	localStorage.removeItem('user')
}
if (localStorage.getItem('token')) {
	localStorage.removeItem('token')
}
if(Cookies.get('purchasedItems')){
	Cookies.remove('purchasedItems')
}

onUser((user) => {
	if (user && user.email) {
		let messageKey = window.settings.apiKeys && window.settings.apiKeys.length && window.settings.apiKeys[0].apiKey
		const emailCipher = cipher(messageKey)
		const cipherOutput = emailCipher(user.email)
		pushToDatalayer('user_email', user.email);
		pushToDatalayer('user_encrypted_email', cipherOutput);
		Cookies.set("user_encrypted_email",cipherOutput)
	}
})

function clevertapInit() {
	var userInfo = userStore.getState().user || {}
	var subscriptionData = userStore.getState().subscription || {}
	if (userInfo.userId) {
		var userProfileData = {
			'Name': userInfo.name,
			'Email': userInfo.email,
			'Identity': userInfo.userId,
			'Phone': userInfo && userInfo.phone ? ((typeof userInfo.phone === 'string' && (userInfo.phone.charAt(0) !== '+')) ? JSON.parse(userInfo.phone) : userInfo.phone) : "", 
			'User Status': userInfo.isSubscribed ? 'Subscribed' : 'Registered and Never Subscribed',
			'registration_method': userInfo.provider,
			'registration_platform': userInfo.registerdVia && capitalize(userInfo.registerdVia.split('_')[0]),
			'registration_date': getUnixTimestamp((userInfo.registeredOn && userInfo.registeredOn.split("T")[0]) || null),
			'registration_country': userInfo.country,
			'MSG-whatsapp': userInfo && userInfo.whatsappConsent ? userInfo.whatsappConsent : false, 
		}
		const { subscriptionInfo = null, subscriptionPlanInfo = null } = subscriptionData.subscription || {};
		if (userInfo.isSubscribed && subscriptionData.subscription && subscriptionInfo) {
			assignIn(userInfo, userStore.getState().user);
			assignIn(userProfileData, {
				'paymentMode': subscriptionInfo.cardType || null,
				'paymentHandler': subscriptionInfo.paymentHandler || null,
				'subscriptionStartDate': getUnixTimestamp((subscriptionInfo.subscriptionStartDate && subscriptionInfo.subscriptionStartDate.split("T")[0]) || null),
				'subscriptionEndDate': getUnixTimestamp((subscriptionInfo.subscriptionEndDate && subscriptionInfo.subscriptionEndDate.split("T")[0]) || null),
				'transactionId': subscriptionInfo.vlTransactionId || null,
				'discountAmount': subscriptionInfo.planAmount || null,
				'paymentPlan': subscriptionPlanInfo ? subscriptionPlanInfo.name : null,
				'freeTrial': subscriptionInfo.freeTrial ? 'Yes' : 'No',
				'country': subscriptionInfo.countryCode || null,
				'currency': subscriptionInfo.currencyCode || null,
				'amount': (subscriptionPlanInfo && subscriptionPlanInfo.planDetails[0]) ? subscriptionPlanInfo.planDetails[0].strikeThroughPrice : null,
			})
			addUserProfile('Site', userProfileData, true)
		} else {
			addUserProfile('Site', userProfileData, true)
		}
		if (getProp(window, "settings.notifications.enableWebPushNotification", null))	requestUserPermission();
	} else {
		//addUserProfile('Site', { 'User Status': 'Anonymous' }, true);
		getProp(window, "settings.notifications.enableAnonymousUserWebPushNotification", null) && anonymousUserPushNotification();
	}
}
if (window.settings.serviceType === 'SVOD') {
	clevertapInit();
	let duration = (Cookies.get('token') && JSON.parse( Cookies.get('token')).duration) || 1800000;	//Time Interval fetched from token expiration | Validity of auth token
	if(duration < 0) {
		duration = 1800000
	}
	setInterval(() => setUserStatus(true), duration); //refreshing the user after a fixed time interval | Default is 1800000ms or 30mins
}

var plans = getQueryVariable('forwardParams')
var app = getQueryVariable('app')
const params = new URLSearchParams(window.location.search)
const offerCode = params.get('offer')
if (offerCode) {
	window.sessionStorage.setItem('offer', offerCode)
}
if (app) {
	window.sessionStorage.setItem('isApp', true)
}
if (plans) {
	const plans = params.get('forwardParams')
	window.sessionStorage.setItem('plans', plans.split('=')[1])
}
var popupName = getQueryVariable('popup')
if (popupName && popup[popupName]){
	if(get(window.settings, 'isMonetizationModelEnabled') && popupName === 'createSubscription'){
		popup['createSubscription02']()
	}else{
		popup[popupName]()
	}
}

function getApp() {
  const isRecaptchaEnabled = get(window, "settings.analytics.recaptchaKeys.enableRecaptcha") && get(window, "settings.analytics.recaptchaKeys.siteKey") && get(window, "settings.analytics.recaptchaKeys.recatpchaV2SiteKey");
  return isRecaptchaEnabled ? (
    <GoogleReCaptchaProvider
      reCaptchaKey={get(window, "settings.analytics.recaptchaKeys.siteKey")}
    >
      <App />
    </GoogleReCaptchaProvider>
  ) : (
    <App />
  );
}


render(getApp(), document.getElementById('root'));
