import axios from 'axios'
import Cookies from 'js-cookie'
import { getToken, setToken, clearToken } from './tokens'
import {flushAllProviders} from './helpers'
import store from './store'
import { subscribeNotificationWs } from './helpers';
import {initSlo} from "~/verimatrix/apis"
import { isContentFilterEnable, updateRecentlyFilterIdOnProfileId, updateRecentlyFilterIdOnSignUpProfileId} from '~/tools/contentFilterFunctions'

export const loginMethod = method => {
  return {
    type: 'ADD_LOGIN_METHOD',
    payload: method
  }
}
export const generateId = _ => {
  const s4 = _ => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
}

export var login = (creds, reloadPage) => {
  const dataLayer = window.dataLayer || { push: _ => {} }
  let currentDevice = localStorage.getItem('deviceId');
  let deviceId = 'browser-'+generateId();
  if(currentDevice) {
    deviceId = currentDevice
  }
  var payload = axios({
    method: 'POST',
    url: `${window.settings.apiBaseUrl}/identity/signin`,
    params: {
      site: window.settings.internalName,
      deviceId:deviceId
    },
    data: creds,
    headers: {
      'x-api-key': window.settings.baseAPIKey
    },
  }).then( res => {
    localStorage.setItem('deviceId', deviceId);
    dataLayer.push({
      'event': 'avod_finish_signin',
    });
    setToken(res.data)
    if(res.data.code === "DEVICE_LIMIT_EXCEEDED") {
      window.location.href = '/user'
    }

    return res.data
  })
  return { type: 'LOGIN', payload: payload }
}


export var fbLogin = (user, mappingId = '') => {
  let utmCookie = Cookies.get('utm')
  let utmData = utmCookie && JSON.parse(utmCookie)
  const dataLayer = window.dataLayer || { push: _ => {} }
  let currentDevice = localStorage.getItem('deviceId');
  let deviceId = 'browser-'+generateId();
  if(currentDevice) {
    deviceId = currentDevice
  }
  var loginParams = {
    site: window.settings.internalName,
    campaign: utmData && utmData.utm_campaign,
    campaign_source: utmData && utmData.utm_source,
    campaign_medium: utmData && utmData.utm_medium,
    utm_params: utmData && utmData.utm_params,
    deviceId:deviceId,
    mappingId:mappingId && mappingId
  }

  var payload = axios({
    method: 'POST',
    url: `${window.settings.apiBaseUrl}/identity/signin/facebook`,
    params: loginParams,
    headers: {
      'x-api-key': window.settings.baseAPIKey
    },
    data: {
      facebookToken: user.facebookToken,
      emailConsent: user.emailConsent,
      vppaCompliance: user.vppaCompliance || null
    }
  }).then( res => {
    localStorage.setItem('deviceId', deviceId);
    dataLayer.push({
      'event': 'avod_finish_registration',
      'registration_method': 'facebook'
    });
    setToken(res.data)
    isContentFilterEnable() && updateRecentlyFilterIdOnSignUpProfileId(res && res.data);
    return res.data
  })

  return { type: 'LOGIN', payload: payload }
}

export var googleLogin = (user, mappingId = '') => {
  let utmCookie = Cookies.get('utm')
  let utmData = utmCookie && JSON.parse(utmCookie)
  const dataLayer = window.dataLayer || { push: _ => {} }
  let currentDevice = localStorage.getItem('deviceId');
  let deviceId = 'browser-'+generateId();
  if(currentDevice) {
    deviceId = currentDevice
  }
  var loginParams = {
    site: window.settings.internalName,
    device: 'web_browser',
    campaign: utmData && utmData.utm_campaign,
    campaign_source: utmData && utmData.utm_source,
    campaign_medium: utmData && utmData.utm_medium,
    utm_params: utmData && utmData.utm_params,
    deviceId:deviceId,
    mappingId:mappingId && mappingId
  }

  var payload = axios({
    method: 'POST',
    url: `${window.settings.apiBaseUrl}/identity/signin/google`,
    params: loginParams,
    data: {
      googleToken: user.googleToken,
      emailConsent: user.emailConsent,
      vppaCompliance: user.vppaCompliance || null
    },
    headers: {
      'x-api-key': window.settings.baseAPIKey
    }
  }).then( res => {
    localStorage.setItem('deviceId', deviceId);
    dataLayer.push({
      'event': 'avod_finish_registration',
      'registration_method': 'google'
    });
    setToken(res.data)
    isContentFilterEnable() && updateRecentlyFilterIdOnSignUpProfileId(res && res.data);
    return res.data
  })

  return { type: 'LOGIN', payload: payload }
}

export const appleLogin = (user, mappingId = '') => {
  const dataLayer = window.dataLayer || { push: _ => {} }
  let currentDevice = localStorage.getItem('deviceId');
  let deviceId = 'browser-'+generateId();
  if(currentDevice) {
    deviceId = currentDevice
  }
  var loginParams = {
    site: window.settings.internalName,
    deviceName: "web_browser",
    platform: "web_browser",
    deviceId: deviceId,
    mappingId:mappingId && mappingId
  };
  const name = user.firstName && user.lastName ? (user.firstName + ' ' + user.lastName) : (user.firstName || null)
  const payload = axios({
    method: "POST",
    url: `${window.settings.apiBaseUrl}/identity/signin/apple`,
    params: loginParams,
    data: {
      AuthenticationCode: user.code,
      userId: user.sub,
      email: user.email,
      identityToken: user.id_token,
      firstName: name,
      lastName: user.lastName,
      vppaCompliance: user.vppaCompliance
    },
    headers: {
      "x-api-key": window.settings.baseAPIKey,
    },
  }).then( res => {
    localStorage.setItem('deviceId', deviceId);
    dataLayer.push({
      'event': 'avod_finish_registration',
      'registration_method': 'apple'
    });
    setToken(res.data);
    isContentFilterEnable() && updateRecentlyFilterIdOnSignUpProfileId(res && res.data);
    return res.data;
  })
  
  return { type: 'LOGIN', payload: payload };
};

export var logout = () => {
  const user = store.getState().user
  const userId = user && user.userId
  sessionStorage.removeItem(userId)
  sessionStorage.removeItem("offer")
  sessionStorage.removeItem("plans")
  sessionStorage.getItem('displayAddPhoneScreen') && sessionStorage.removeItem('displayAddPhoneScreen')
  var payload =
    getToken().then(token => {
      return axios({
        method: 'POST',
        url: `${window.settings.apiBaseUrl}/identity/signout`,
        params: { site: window.settings.internalName },
        headers: { 
          Authorization: token,
          'x-api-key': window.settings.baseAPIKey
        }
      }).then(async res => {
        if(user && user.istveLoggedIn){
          await flushAllProviders()
          initSlo().then((res) => {
            clearToken()
          })
        } else {
          clearToken()
        }
        return res.data
      }).catch(e => {
        clearToken()
        console.error(e)
      })
    })

  return { type: 'LOGOUT', payload: payload }
}

export var signup = (user, mappingId = '') => {
  let utmCookie = Cookies.get('utm')
  let utmData = utmCookie && JSON.parse(utmCookie)
  const dataLayer = window.dataLayer || { push: _ => {} }
  let currentDevice = localStorage.getItem('deviceId');
  let deviceId = 'browser-'+generateId();
  if(currentDevice) {
    deviceId = currentDevice
  }
  var signupParams = {
    site: window.settings.internalName,
    campaign: utmData && utmData.utm_campaign,
    campaign_source: utmData && utmData.utm_source,
    campaign_medium: utmData && utmData.utm_medium,
    utm_params: utmData && utmData.utm_params,
    deviceId:deviceId,
    mappingId:mappingId && mappingId
  }

  var promise = axios({
    method: 'POST',
    url: `${window.settings.apiBaseUrl}/identity/signup`,
    params: signupParams,
    data: user,
    headers: {
      'x-api-key': window.settings.baseAPIKey
    }
  }).then( res => {
    localStorage.setItem('deviceId', deviceId);
    dataLayer.push({
      'event': 'avod_finish_registration',
      'registration_method': 'viewlift'
    });
    setToken(res.data)
    isContentFilterEnable() && updateRecentlyFilterIdOnSignUpProfileId(res && res.data)
    let data = res.data
    data["emailConsent"] = user.emailConsent
    return data
  })
  
  return { type: 'SIGNUP', payload: promise }
}

export var update = (data) => {
  let currentDeviceID = localStorage && localStorage.getItem('deviceId');
  if(currentDeviceID) {
    data["deviceId"] = currentDeviceID 
  } else {
    let deviceId = "browser-" + generateId();
    data["deviceId"] = deviceId
  }
  var promise = getToken().then(token => {
    return axios({
      url: '/identity/user',
      baseURL: window.settings.apiBaseUrl,
      method: 'POST',
      params: {
        site: window.settings.internalName
      },
      headers: {
        'Authorization': token,
        'x-api-key': window.settings.baseAPIKey
      },
      data: JSON.stringify(data),
      contentType: 'application/json'
    }).then( res => {
      setToken(res.data)
      return res
    })
  })
  return { type: 'UPDATE', payload: promise }
}

export var updateUserNickName = async (data) => {
  return getToken().then(token => {
    return axios({
      url: '/identity/user/nickName',
      baseURL: window.settings.apiBaseUrl,
      method: 'PATCH',
      headers: {
        'Authorization': token,
        'x-api-key': window.settings.baseAPIKey
      },
      data: JSON.stringify(data),
      contentType: 'application/json'
    }).then( res => {
      return res
    })
  })
}

export var refresh = () => {
  var promise = getToken().then(token => {
    return axios({
      url: 'identity/user',
      baseURL: window.settings.apiBaseUrl,
      method: 'GET',
      params: {
        site: window.settings.internalName
      },
      headers: { 
        'Authorization': token,
        'x-api-key': window.settings.baseAPIKey 
      }
    }).then(res => {
      const resData = res && res.data;
      isContentFilterEnable() && updateRecentlyFilterIdOnProfileId(resData);
      return res;
    })
    .catch(error => {
      const responseStatus = error && error.response && error.response.status    
      if((responseStatus === 401 || responseStatus === 404)) {
        logout()
      } else {
        return
      }
    })
  })
  .catch(error => {
    /***  When user forcefully removed from another device then clean all the session related this and move to home page. **/ 
    const responseStatus = error && error.response && error.response.status
    
    if((responseStatus === 401) || (responseStatus === 403)) {
      clearToken()
      return
    } else {
      return
    }
  })

  return { type: 'REFRESH', payload: promise }
}

export var subscribe = (o) => {
  let subscription = localStorage.getItem('subscription') && localStorage.getItem('subscription')
    let userCurrentPayment = null, currentStatus = null
    if(subscription) {
      subscription = JSON.parse(subscription)
      userCurrentPayment = subscription && subscription.subscription && subscription.subscription.subscriptionInfo.paymentHandler
      currentStatus = subscription && subscription.subscription && subscription.subscription.subscriptionStatus
    }
    let method = (userCurrentPayment === "PREPAID" && currentStatus === "COMPLETED") ? "PUT" : "POST"
    const payload = getToken().then(token => {
    const user = store.getState().user
    const amazonPay = o.paymentUniqueId
    const paypal = o.vlTransactionId
    const stripe = o.billingToken
    const bkash = o.isBkash
    const data = {
      subscription: o.subscription || 'stripe',
      siteId: window.settings.siteId,
      platform: 'web_browser',
      planId: o.plan.id,
      planIdentifier: o.plan.identifier,
      userId: user.userId,
      currencyCode: o.plan.planDetails[0].recurringPaymentCurrencyCode,
      appliedOffers: o.offerCode ? [ o.offerCode ] : undefined,
      ...(paypal && { vlTransactionId: o.vlTransactionId }),
      ...(amazonPay && { paymentUniqueId: o.paymentUniqueId }),
      ...(stripe && {
        stripeToken: o.billingToken.stripeToken.id || o.billingToken.stripeToken.card.id,
        requestId: o.billingToken.requestId || o.billingToken.stripeToken.card.requestId,
      }),
      ...(bkash 
        && { 
          paymentHandler: 'BKASH', 
          redirectURL: o.redirectURL, 
          udf10: o.offerCode ? o.offerCode.code : null 
        }
      ),
    }

    return axios({
      method: method,
      url: `${window.settings.apiBaseUrl}/subscription/subscribe`,
      params: { platform: 'web_browser', site: window.settings.internalName },
      headers: { 
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey
      },
      data: data
    })
  }).then(async res => { 
    await subscribeNotificationWs(o)
    return res
  });
  return { type: 'CREATE_SUBSCRIPTION', payload: payload }
}

export var cancelSubscribe = () => {
  return { type: 'CANCEL_SUBSCRIPTION'}
}

export var getCardInfo = (user) => {
  var payload = getToken().then(token => {
    return axios({
      method: 'GET',
      url: `${window.settings.apiBaseUrl}/payments/billing-history/last4`,
      params: {
        userId: user.userId,
        site: window.settings.internalName
      },
      headers: {
        'Authorization': token,
        'x-api-key': window.settings.baseAPIKey
      }
    }).then(res =>{
      return res.data
    })
  })

  return { type: "UPDATE_CARD", payload: payload };
}

export var transaction = (data) => {
  return  getToken().then(token => {
    return axios({
      method: 'POST',
      url: `${window.settings.apiBaseUrl}/transaction/transaction`,
      headers: {
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey
      },
      data: JSON.stringify(data)
    })
  })
}


export var getMySubscription = (cb) => {
  var payload = getToken().then( (token, refreshToken) => {
    var userId = store.getState().user.userId;
    return axios({
      method: 'GET',
      url: `${ window.settings.apiBaseUrl }/subscription/user`,
      params: { site: window.settings.internalName, userId: userId },
      headers: { 
        Authorization: token,   
        'x-api-key': window.settings.baseAPIKey 
      }
    }).then( res => {
      if (cb) cb()
      return res.data
    })
  })
  return { type: 'GET_SUBSCRIPTION', payload: payload}
}

export var getOfferDetails = (offerId) => {
  return  getToken().then(token => {
    return axios({
      method: 'GET',
      url: `${window.settings.apiBaseUrl}/subscription/offer/${offerId}`,
      params: { site: window.settings.internalName},
      headers: {
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey
      }
    })
  })
}

export var subscribeMercado = (o) => {
  var user = store.getState().user;
  var data = {
    email: o.email,
    mercadopagoToken: o.id,
    firstSixDigits: o.firstSixDigits,
    subscription: 'mercadopago',
    siteId: window.settings.siteId,
    platform: 'web_browser',
    planId: o.plan.id,
    planIdentifier: o.plan.identifier,
    userId: user.userId,
    currencyCode: o.plan.planDetails[0].recurringPaymentCurrencyCode,
    appliedOffers: o.offerCode ? [ o.offerCode ] : undefined,
    issuerId: o.issuerId,
    paymentMethodId: o.paymentMethodId
  }
  var payload = getToken()
  .then((token) => {
    return axios({
      method: 'POST',
      url: `${window.settings.apiBaseUrl}/subscription/subscribe`,
      params: { site: window.settings.internalName, platform: 'web_browser' },
      headers: { 
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey
      },
      data: data
    })
  })
  .catch(err=>{
    console.log(err);
  })
  return { type: 'CREATE_SUBSCRIPTION', payload: payload }
}


export const tveSignin = (user) => {
  let deviceId = 'browser-'+generateId()
  var payload = axios({
    method: 'POST',
    url: `${window.settings.apiBaseUrl}/identity/signin/tve`,
    params: { site: window.settings.internalName, platform: 'web', deviceId },
    headers: { 
      'x-api-key': window.settings.baseAPIKey
    },
    data: user
  })
  .then((d) => {
    d.data.istveLoggedIn = true
    setToken(d.data)
    return d.data
  })
  return { type: 'TVE_LOGIN', payload: payload }
}

export const tveLoginRefresh = (data) => {
  return {type: 'TVE_LOGIN_REFRESH', payload: data}
}

export const updateLatestTransaction = (transactionProps) => {
  return {type: 'UPDATE_LATEST_TRANSACTION', payload: transactionProps}
}

export const checkPassword = async (creds) => {
  return await (
    axios({
    method: 'POST',
    url: `${window.settings.apiBaseUrl}/identity/signin`,
    params: {
      site: window.settings.internalName
    },
    data: creds,
    headers: {
      'x-api-key': window.settings.baseAPIKey
    },
    }).then( res => {
      return res
    })
    .catch(err=> {
      console.log(err);
    })
  )
}

export const setParentalControls = async (parentalControlsData) => {
  return await getToken().then(token => {
    return (
      axios({
      method: 'PUT',
      url: `${window.settings.apiBaseUrl}/identity/parental`,
      data: parentalControlsData,
      headers: {
        Authorization: token,
        'x-api-key': window.settings.baseAPIKey
      },
      }).then( res => {
        return res
      })
      .catch(err=> {
        console.log(err);
      })
    )
  })
}

export const updatePin = (pinNumber) => {
  return { type: 'ADD_PIN', payload: pinNumber }
}

export const updateRating = (rating) => {
  return { type: 'ADD_RATING', payload: rating }
}

export const enableDisableParentalControl = (parentalControlToggle) => {
  return { type: 'PARENTAL_CONTROL', payload: parentalControlToggle }
}

export const addPassword = (passwordEnabled) => {
  return { type: 'ADD_PASSWORD', payload: passwordEnabled }
}

export const updateEmailConsent = (data) => {
  update(data)
  return { type: 'ADD_EMAIL_CONSENT', payload: data.emailConsent }
}

export const addClevertapWhatsAppConsent = (clevertapWhatsAppConsent) => {
  return { type: 'ADD_CLEVERTAP_WHATSAPP_CONSENT', payload: clevertapWhatsAppConsent }
}

export const refreshTve = (data) => {
  return { type: 'REFRESH_TVE', payload: data }
}
